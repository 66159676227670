import "./App.css";

import Router from "./router/AppRouter";

import { useMemo } from "react";
import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import queryClient from "./utils/react-query";
import { GoogleOAuthProvider } from "@react-oauth/google";
import generateTheme from "./theme";
import ThemeStore from "./theme/ThemeStore";

function App() {
  const themeSelected = ThemeStore((state) => state.theme);
  const theme = useMemo(() => generateTheme(themeSelected), [themeSelected]);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <GoogleOAuthProvider clientId="352697467066-pqq3qmf2v0thp36or9un6qio784ag8sm.apps.googleusercontent.com">
            {/* <CardVrac />*/}
            <Router />
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </GoogleOAuthProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
