import { Box, IconButton } from "@mui/material";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import Brightness2RoundedIcon from "@mui/icons-material/Brightness2Rounded";

import ThemeStore from "../../../../theme/ThemeStore";

function ThemeSwitcher() {
  const theme = ThemeStore((state) => state.theme);
  const changeTheme = ThemeStore((state) => state.changeTheme);
  return (
    <Box display="flex" alignItems="center" ml={1} mr={2}>
      <IconButton onClick={changeTheme}>
        {theme === "light" ? (
          <LightModeRoundedIcon />
        ) : (
          <Brightness2RoundedIcon />
        )}
      </IconButton>
    </Box>
  );
}

export default ThemeSwitcher;
