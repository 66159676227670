import { create } from "zustand";

const matchMediaTheme = window.matchMedia("(prefers-color-scheme:dark)").matches
  ? "dark"
  : "light";
const initialThemeValue =
  localStorage.getItem("theme") !== null
    ? localStorage.getItem("theme")
    : matchMediaTheme;

const ThemeStore = create((set) => ({
  theme: initialThemeValue,
  changeTheme: () =>
    set((state) => {
      localStorage.setItem("theme", state.theme === "light" ? "dark" : "light");
      return { ...state, theme: state.theme === "light" ? "dark" : "light" };
    }),
}));

export default ThemeStore;
