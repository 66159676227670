import { create } from "zustand";
import jwt_decode from "jwt-decode";
import { googleLogout } from "@react-oauth/google";

const LoginStore = create((set) => ({
  token: localStorage.getItem("token") ?? null,
  admin: localStorage.getItem("admin") ?? false,
  login: (jwt) =>
    set((state) => {
      localStorage.setItem("token", jwt);
      const decoded = jwt_decode(jwt);
      const flag =
        decoded.email === "hugo.suissia@gmail.com" ||
        decoded.email === "aurelie.leportier@gmail.com"
          ? true
          : false;
      localStorage.setItem("admin", flag);
      return { ...state, token: jwt, admin: flag };
    }),
  logout: () =>
    set((state) => {
      localStorage.removeItem("token");
      localStorage.removeItem("admin");
      googleLogout();
      return { ...state, token: null, admin: false };
    }),
}));

export default LoginStore;
