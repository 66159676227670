import { BrowserRouter } from "react-router-dom";

import Routes from "./Routes";

const AppRouter = () => (
  <BrowserRouter>
    <Routes />
  </BrowserRouter>
);

export default AppRouter;
