import { Box, Grid, Link, Paper } from "@mui/material";
import { NavLink as RouterLink } from "react-router-dom";

import ThemeSwitcher from "./components/ThemeSwitcher";
import LoginZone from "./components/LoginZone";

import Pokedex from "../../assets/images/pokedex.png";
import Cards from "../../assets/images/cards.png";
import Collection from "../../assets/images/collection.png";

function Header() {
  return (
    <Paper
      elevation={0}
      square
      sx={{
        border: 0,
        borderBottom: 1,
        borderStyle: "solid",
        borderColor: "divider",
        display: "flex",
        alignItems: "center",
        gap: 1,
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          mr: { xs: 0, sm: 0, md: 2 },
          p: 1,
          whiteSpace: "nowrap",
        }}
      >
        Notre Pokedex
      </Box>
      <Grid container spacing={3} sx={{ p: 1 }}>
        <Grid
          item
          xs={10}
          sm={6}
          sx={{
            display: "flex",
            justifyContent: "start",
            gap: 1,
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              height: "100%",
            }}
          >
            <Link
              component={RouterLink}
              to="/"
              color="error"
              sx={{ display: "flex", alignItems: "center", gap: 1 }}
            >
              <img src={Pokedex} alt="Pokedex" width={32} />
              <Box sx={{ display: { xs: "none", sm: "none", md: "inline" } }}>
                Pokédex
              </Box>
            </Link>
            <Link
              component={RouterLink}
              to="vrac-cards"
              color="error"
              sx={{ display: "flex", alignItems: "center", gap: 1 }}
            >
              <img src={Cards} alt="Pokedex" width={32} />
              <Box sx={{ display: { xs: "none", sm: "none", md: "inline" } }}>
                Nos cartes
              </Box>
            </Link>
            <Link
              component={RouterLink}
              to="collections"
              color="error"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                height: "100%",
              }}
            >
              <img src={Collection} alt="Pokedex" width={32} />
              <Box sx={{ display: { xs: "none", sm: "none", md: "inline" } }}>
                Par Collection
              </Box>
            </Link>
          </Box>
        </Grid>
        <Grid
          item
          xs={2}
          sm={6}
          sx={{ display: "flex", justifyContent: "end" }}
        >
          <ThemeSwitcher />
          <LoginZone />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default Header;
