import { IconButton } from "@mui/material";
import { LogoutRounded } from "@mui/icons-material";
import { GoogleLogin } from "@react-oauth/google";

import LoginStore from "../../../../utils/loginStore";

function LoginZone() {
  const login = LoginStore((state) => state.login);
  const logout = LoginStore((state) => state.logout);
  const token = LoginStore((state) => state.token);
  return (
    <>
      {token ? (
        <IconButton
          onClick={() => {
            logout();
          }}
        >
          <LogoutRounded />
        </IconButton>
      ) : (
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            login(credentialResponse.credential);
          }}
          onError={() => {
            console.log("Login Failed");
          }}
          useOneTap
          type="icon"
          shape="circle"
          theme="filled_blue"
        />
      )}
    </>
  );
}

export default LoginZone;
