import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "../layout/Layout";

const Pokedex = lazy(() => import("../pages/Pokedex"));
const CardVrac = lazy(() => import("../pages/CardVrac"));
const Collections = lazy(() => import("../pages/Collections"));

const publicRoutes = () => (
  <Route path="/" element={<Layout />}>
    <Route index element={<Pokedex />} />
    <Route path="vrac-cards" element={<CardVrac />} />
    <Route path="collections" element={<Collections />} />
  </Route>
);

const AllRoutes = () => <Routes>{publicRoutes()}</Routes>;
export default AllRoutes;
