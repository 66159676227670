import { createTheme } from "@mui/material";

const generateTheme = (mode) => {
  const theme = {
    breakpoints: {
      keys: ["xs", "sm", "md", "lg", "xl"],
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    palette: {
      mode,
      ...(mode === "light"
        ? {
            text: {
              primary: "#151515",
              disabled: "rgba(0, 0, 0, 0.6)",
            },
            action: {
              disabled: "rgba(0, 0, 0, 0.6)",
              disabledBackground: "rgba(0, 0, 0, 0.48)",
            },
            background: {
              default: "#f2f2f2",
              paper: "#fff",
            },
          }
        : {
            background: {
              default: "#1e1f22",
              paper: "#313338",
            },
          }),
    },
  };
  return createTheme(theme);
};

export default generateTheme;
