import { Box } from "@mui/material";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";

import Header from "./Header";

function Layout() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      position="relative"
    >
      <Header />
      <Box id="main">
        <Suspense fallback={<div>Loading...</div>}>
          <Outlet />
        </Suspense>
      </Box>
    </Box>
  );
}
export default Layout;
